import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import GoogleplayImg from '../../assets/images/Button_googleplay.png';
import config from '../../config';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer__top"></div>
      <div className="footer__content">
        <div className="container flex justify-between">
          <div className="footer__links flex-inline align-center">
            <Link className="footer__link footer__link--privacy" to={`/privacy`}>
              Privacy Policy
            </Link>
            <Link className="footer__link footer__link--terms" to={`/terms`}>
              Terms and Contitions
            </Link>
            <Link className="footer__link footer__link--delect-account" to={`/delete-account`}>
              Delete account
            </Link>
          </div>
          <div className="footer__buttons flex-inline">
            <a href={config.googleplay} className="button-googleplay">
              <img src={GoogleplayImg} alt="googleplay" />
            </a>
            {/* <a href={config.apk} className="button-download">
              Download <i className="ri-arrow-down-circle-line"></i>
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
